<template>
    <div class="Solutions">
        <Nav
            nav-title="解决方案"
            :pic-url="picUrl"
        >
            <el-collapse
                v-model="activeName"
                accordion
            >
                <el-collapse-item
                    title="三维可视化解决方案"
                    name="1"
                >
                    <ul class="left-nav-list">
                        <li
                            v-for="item in list1"
                            :key="item.id"
                            @click="changeTab(item.id,item.tabIndex)"
                            :class="{selectedLi:selectedIndex=== item.id}"
                        >
                            <router-link :to="item.url">
                                <i class="iconfont icon-youjiantou1" />
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </el-collapse-item>
                <el-collapse-item
                    title="材料设计与器件仿真解决方案"
                    name="2"
                >
                    <ul class="left-nav-list">
                        <li
                            v-for="item in list2"
                            :key="item.id"
                            @click="changeTab(item.id,item.tabIndex)"
                            :class="{selectedLi:selectedIndex=== item.id}"
                        >
                            <router-link :to="item.url">
                                <i class="iconfont icon-youjiantou1" />
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </el-collapse-item>
                <ul
                    class="list3-ul"
                    name="3"
                >
                    <li
                        @click="changeTab('5','2-3-3')"
                        :class="{selectedLi:selectedIndex === '5'}"
                    >
                        <router-link to="/solutions/digital-twin">
                            <span>工业数字孪生解决方案</span>
                            <i class="iconfont icon-youjiantou" />
                        </router-link>
                    </li>
                </ul>
                <el-collapse-item
                    title="高性能计算资源解决方案"
                    name="4"
                >
                    <ul class="left-nav-list">
                        <li
                            v-for="item in list3"
                            :key="item.id"
                            @click="changeTab(item.id,item.tabIndex)"
                            :class="{selectedLi:selectedIndex=== item.id}"
                        >
                            <router-link :to="item.url">
                                <i class="iconfont icon-youjiantou1" />
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </el-collapse-item>
            </el-collapse>
        </Nav>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'Solutions',
  components: {
      Nav
  },
  data () {
        return {
          picUrl: require('../assets/images/bgImg/jiejuefangan.png'),
          activeName: '1',
          list1: [
            {
              id: '0',
              url: '/solutions/3ds/sci',
              name: '科研',
              tabIndex: '2-1-1'
            },
            {
              id: '1',
              url: '/solutions/3ds/edu',
              name: '教育',
              tabIndex: '2-2-1'
            },
            {
              id: '2',
              url: '/solutions/3ds/ind',
              name: '生产制造',
              tabIndex: '2-3-1'
            }
          ],
          list2: [
            {
              id: '3',
              url: '/solutions/material/sci',
              name: '科研',
              tabIndex: '2-1-2'
            },
            {
              id: '4',
              url: '/solutions/material/ind',
              name: '生产制造',
              tabIndex: '2-3-2'
            }
          ],
          list3: [
            {
              id: '6',
              url: '/solutions/hpc/server-configuration',
              name: '服务器配置服务',
              tabIndex: '2-4-1'
            },
            {
              id: '7',
              url: '/solutions/hpc/rental-service',
              name: '超算机时租赁服务',
              tabIndex: '2-4-2'
            }
          ],
          selectedIndex: '-1',
          selectedTabIndex: ''
        }
  },
  methods: {
    changeTab (id, tabIndex) {
      this.$store.commit('changeTopNav', tabIndex)
      this.selectedIndex = id
    }
  },
  watch: {
    '$store.state.selectedTabIndex': function (newVal, oldVal) {
        if (newVal === '2-1-1') {
          this.selectedIndex = '0'
          this.activeName = '1'
        } else if (newVal === '2-1-2') {
            this.selectedIndex = '3'
            this.activeName = '2'
        } else if (newVal === '2-2-1') {
            this.selectedIndex = '1'
            this.activeName = '1'
        } else if (newVal === '2-3-1') {
            this.selectedIndex = '2'
            this.activeName = '1'
        } else if (newVal === '2-3-2') {
            this.selectedIndex = '4'
            this.activeName = '2'
        } else if (newVal === '2-3-3') {
            this.selectedIndex = '5'
            this.activeName = '3'
        } else if (newVal === '2-4-1') {
            this.selectedIndex = '6'
            this.activeName = '4'
        } else if (newVal === '2-4-2') {
          this.selectedIndex = '7'
          this.activeName = '4'
        }
    }
  },
  created () {
      this.selectedTabIndex = this.$store.state.selectedTabIndex
    if (this.selectedTabIndex === '2-1-1') {
       this.selectedIndex = '0'
        this.activeName = '1'
    } else if (this.selectedTabIndex === '2-1-2') {
        this.selectedIndex = '3'
        this.activeName = '2'
    } else if (this.selectedTabIndex === '2-2-1') {
        this.selectedIndex = '1'
        this.activeName = '1'
    } else if (this.selectedTabIndex === '2-3-1') {
      this.selectedIndex = '2'
      this.activeName = '1'
    } else if (this.selectedTabIndex === '2-3-2') {
        this.selectedIndex = '4'
        this.activeName = '2'
    } else if (this.selectedTabIndex === '2-3-3') {
        this.selectedIndex = '5'
        this.activeName = '3'
    } else if (this.selectedTabIndex === '2-4-1') {
        this.selectedIndex = '6'
        this.activeName = '4'
    } else if (this.selectedTabIndex === '2-4-2') {
      this.selectedIndex = '7'
      this.activeName = '4'
    }
  }
}
</script>
<style lang="less" scoped>
.Solutions{
  .left-nav-list{
      list-style: none;
      padding-left: 0;
      li{
        width: 100%;
        padding: 0.625rem;
        border-bottom: 1px solid #f2f2f2;
        a{
          width: 100%;
          height: 100%;
          display: block;
          color: rgba(0,0,0,.65);
          i{
            color: rgba(0,0,0,.65);
          }
          span{
          font-size:1rem;

          }
        }
      }

  }
  .selectedLi{
      background: rgb(245, 247, 250);
      border-radius: 5px;
      span{
        font-weight: 600;
      }
  }
  /deep/.el-collapse-item__wrap{
    border-bottom: none;
  }
  /deep/.el-collapse-item__header{
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.65);
  }
  /deep/.el-collapse{
    border-top: none;
  }
  /deep/.el-collapse-item__content{
    padding-bottom: 0;
  }
  .list3-ul{
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li{
        height: 48px;
        line-height: 48px;
        border-bottom:1px solid #EBEEF5;
        a{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: inherit;
          span{
            color: rgba(0, 0, 0, 0.65);
            font-size: 1rem;
          }
          i{
            margin-right: 8px;
            font-size: 20px;
              color: rgba(0, 0, 0, 0.45);
          }
        }
      }
  }
}
</style>
