<template>
    <div class="Nav">
        <!-- 背景图 -->
        <b-row>
            <div
                class="bg-img"
                :style="{'backgroundImage': 'url(' + picUrl + ')'}"
            />
        </b-row>
        <!-- content -->
        <b-row
            align-h="center"
            class="padding-content"
        >
            <b-col
                lg="12"
                cols="12"
                class="nav-content"
            >
                <b-row>
                    <b-col
                        lg="8"
                        xl="9"
                        order-sm="1"
                        order-md="2"
                        class="content"
                    >
                        <div class="right-content-title">
                            <el-divider direction="vertical" />
                            <span class="content-title-span">{{ contentTitle }}
                                <span v-if="isShowA">
                                    <span
                                        @click="toLeaveMessage"
                                        class="leaveMessage-a"
                                    >欢迎留言</span>
                                    <i class="iconfont icon-a-youjiantouzhixiangyoujiantou1" />
                                </span>
                            </span>
                        </div>
                        <router-view
                            class="right-content"
                            @contentTitle="getContentTitle"
                            @isShowA="getIsShowA"
                            ref="childrenContent"
                        />
                        <a
                            href="mailto:fusongsoochow@163.com"
                            target="_blank"
                            v-if="contentTitle !== '公司简介' && contentTitle !== '联系我们'"
                        >
                            联系我们，获取更多信息<i class="iconfont icon-a-youjiantouzhixiangyoujiantou1" />
                        </a>
                    </b-col>
                    <!-- 左侧导航 -->
                    <b-col
                        lg="4"
                        xl="3"
                        order-sm="2"
                        order-md="1"
                        class="left-nav"
                    >
                        <div class="left-nav-title">
                            <el-divider direction="vertical" />
                            <span class="content-nav-span">{{ navTitle }}</span>
                        </div>
                        <slot />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'Nav',
   props: {
        navTitle: {
            type: String,
            default: '关于复数'
        },
        picUrl: {
            type: String,
            default: require('../assets/images/bgImg/guanyufushu.png')
        }
    },
  data () {
        return {
          contentTitle: '',
          isShowA: false
        }
      },
      methods: {
        getContentTitle (msg) {
          console.log(msg)
          this.contentTitle = msg
        },
        getIsShowA (data) {
          this.isShowA = data
        },
        toLeaveMessage: function () {
          this.$refs.childrenContent.parentEvent()
        }
      }
}
</script>
<style lang="less" scoped>
.Nav{
  .leaveMessage-a{
    margin-left: 10px;
    text-decoration: underline!important;
    cursor: pointer;
  }
  .bg-img{
    width: 100%;
    height: 170px;
    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
  }
  /deep/.el-divider--vertical{
    width: 0.625rem;
    height: 1.875rem;

  }
  /deep/.el-divider{
    background-color: #0074FF;
  }
  .right-content-title,.left-nav-title{
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom:1px solid #d7d7d7;
      padding: 0.625rem 0;
      margin-bottom: 1.25rem;
      span{
        color: #0074FF;
      }
    }
  .nav-content{
    margin-top: 3.125rem;
    .content{
      padding-bottom: 3.125rem;
      .right-content{}
    }
    .left-nav{
    }
  }
      @media only screen and (min-width: 576px) {
        .content-nav-span,.content-title-span{
          font-size:1.4375rem;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 575.98px){
        .content-nav-span,.content-title-span{
          font-size: 16px;
      }
    }
     @media (max-width: 320px) {
         .content-nav-span,.content-title-span{
           font-size: 14px;
      }
    }

}
</style>
